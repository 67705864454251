<template>
  <div>
    <c-tab
      :inlineLabel="true"
      :dense="true"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :param.sync="popupParam"
          :disabledAppr="true"
          :workResultDatas.sync="workResultDatas"
          :workResultEquips.sync="workResultEquips"
          :disabledObj.sync="disabledObj"
          @disabledSet="disabledSet"
          @saveWorkResult="saveWorkResult"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
export default {
  name: 'work-order-plan01',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        newEquipmentCd: '',
        newTitle: '',
        newWoWorkTypeCd: '', // 작업유형 (PM)
        workPlanId: '',
        workResultId: '',
        woRequestId: '',
        plantCd: null,
        woWorkPlanStepCd: '',
        copyFlag: 'N',
        disabledAppr: true,
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      workResultDatas: [],
      workResultEquips: [],
      splitter: 5,
      tab: 'planInfo',
      editable: true,
      detailUrl: '',
      completeUrl: '',
      deleteUrl: '',
      isComplete: false,
      disabledObj: {
        type: Object,
        default: () => ({
          disabled: false,
        }),
      },
    };
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      return numHeight + 'px';
    },
    tabItems() {
      return [
        { key: uid(), name: 'planInfo', icon: 'edit', label: '오더내역', component: () => import(`${"@/pages/wod/plan/workOrderResultDirection.vue"}`) },
        { key: uid(), name: 'team', icon: 'task_alt', label: '자재 및 서비스', component: () => import(`${"@/pages/wod/plan/workOrderResultWork.vue"}`) },
        { key: uid(), name: 'team1', icon: 'task_alt', label: '설비별 고장정보', component: () => import(`${"@/pages/wod/plan/workOrderResultBreakdown.vue"}`) },
        { key: uid(), name: 'team2', icon: 'photo', label: '작업결과 사진', component: () => import(`${"@/pages/wod/plan/workOrderResultFile.vue"}`) },
      ]
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = false
    },
    stepBystep2(_param) {
      this.$emit('emitStep', {
        name: 'stepBystep',
        param: {
          stepCd: _param.stepCd,
          woWorkPlanStepCd: _param.woWorkPlanStepCd,
        }
      })
    },
    closePopup() {
      this.$emit('emitStep', {
        name: 'closePopup'
      })
    },
    saveWorkResult(_works, _equips) {
      this.workResultDatas = [];
      this.workResultEquips = [];

      let equips = [];
      let cntequip = 0;
      this.$_.forEach(_equips, _item => {
        equips.push(_item.equipmentCd);
        this.workResultEquips.push({
          name: 'tab' + cntequip,
          label: _item.equipmentNo + '/' + _item.equipmentName,
          param: {workResultId: _item.workResultId, equipmentCd: _item.equipmentCd},
          component: () => import(`${"@/pages/wod/plan/workOrderResultBreakdownEquip.vue"}`),
          key: uid(),
        })
        cntequip++;
      });
      let cnt = 0;
      this.$_.forEach(_works, item => {
        this.workResultDatas.push({
          name: 'tab' + cnt,
          label: item.sortOrder + '.(' + item.workOprTypeName + ') ' + item.workResultWorkName,
          param: {workResultId: item.workResultId, workResultWorkId: item.workResultWorkId, equips: equips},
          component: () => import(`${"@/pages/wod/plan/workOrderResultService.vue"}`),
          key: uid(),
        })
        cnt++;
      });
    },
    disabledSet(_bool) {
      this.disabledObj.disabled = _bool;
    }
  }
};
</script>
